import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import UpcomingBox from '../components/Battles/UpcomingBox.vue';
import GreenBox from '../components/Battles/GreenBox.vue';
import BlueBox from '../components/Battles/BlueBox.vue';
import FinishedBox from '../components/Battles/FinishedBox.vue';
import functions from '../components/functions';
import Axios from 'axios';
export default {
  created: function created() {
    //-------pozadie stranky-------
    document.getElementsByTagName('body')[0].classList.add('bgImageBlue');
    this.zistiFirstEverLoad();
  },
  mixins: [functions],
  components: {
    GreenBox: GreenBox,
    BlueBox: BlueBox,
    FinishedBox: FinishedBox,
    UpcomingBox: UpcomingBox
  },
  computed: {
    getAllPaidBattlesList: function getAllPaidBattlesList() {
      return this.$store.getters.getAllPaidBattles({
        paid: true
      });
    },
    getBattleList: function getBattleList() {
      return this.$store.getters.getBattleList;
    },
    getModalLoading: function getModalLoading() {
      return this.$store.getters.getModalLoading;
    },
    getBattleCount: function getBattleCount() {
      return this.$store.getters.getBattleCount;
    },
    pocetExclusiveBattlov: function pocetExclusiveBattlov() {
      if ('exclusiveContest' in this.getBattleCount) {
        return parseInt(this.getBattleCount.activeContest);
      }

      return 0;
    },
    getAllBattlesId: function getAllBattlesId() {
      return this.$store.getters.getBattleList.map(function (el) {
        return el.battleSettings.id;
      });
    },
    showButtonLoadBattles: function showButtonLoadBattles() {
      if ('exclusiveContest' in this.getBattleCount) {
        if (this.getBattleCount.exclusiveContest > this.getAllPaidBattlesList.length) {
          return true;
        }
      }

      return false;
    }
  },
  methods: {
    sortedBattleList: function sortedBattleList(battle) {
      return battle.sort(function (a, b) {
        if (a.battleSettings.toBattleStart + a.battleSettings.toBattleEnd + a.battleSettings.toVoteStart < b.battleSettings.toBattleStart + b.battleSettings.toBattleEnd + b.battleSettings.toVoteStart) {
          return 1;
        }

        if (a.battleSettings.toBattleStart + a.battleSettings.toBattleEnd + a.battleSettings.toVoteStart > b.battleSettings.toBattleStart + b.battleSettings.toBattleEnd + b.battleSettings.toVoteStart) {
          return -1;
        }

        return 0;
      });
    },
    loadExclusiveBattles: function loadExclusiveBattles() {
      var _this = this;

      this.$store.commit('setModalLoading', true);
      var udaje = {
        'battles': JSON.stringify(this.getAllBattlesId),
        'type': 'exclusive'
      };
      Axios.post(this.$store.getters.URLAJAX + '/handler.php?page=load-more-battles', udaje).then(function (response) {
        var res = response.data;

        if (res.return) {
          if ('battleCount' in res) {
            //-----------------zapiseme pocty contestov
            _this.$store.commit('setBattleCount', res.battleCount);
          }

          if (res.battleList.length == 0) {
            _this.showButtonLoadBattles = false;
          } else {
            _this.$store.dispatch('addToBattles', res.battleList);
          }
        } else {
          _this.$store.commit('setErrorModal', {
            visible: true,
            data: res.returnMsg
          });
        }
      }).catch(function (e) {
        _this.$store.commit('setErrorModal', {
          visible: true,
          data: "#159 Network error" + e + ""
        });
      }).finally(function () {
        _this.$store.commit('setModalLoading', false);
      });
    },
    zistiFirstEverLoad: function zistiFirstEverLoad() {
      if (!localStorage.getItem('CFexclusiveContests')) {
        //--------ak otvara okno s tym ze sa otvara este top100 alebo nejake konkretne vysledky nespravi nic
        if (!this.$route.params.action) {
          this.$store.commit('setUpperModal', {
            visible: true,
            whatToShow: 'FirstEverLoadExclusiveContests',
            data: {}
          });
          localStorage.setItem('CFexclusiveContests', JSON.stringify(true));
        }
      } else {
        this.$store.commit('setFirstEverLoad', {
          exclusiveContests: true
        });
      }
    }
  }
};